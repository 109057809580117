/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.navbar-radius {
  border-radius: 10rem !important;
}

[data-col='1-column'] {
  .header-navbar {
    // Custom width for floating nav
    &.floating-nav {
      width: calc(
              100vw - (100vw - 100%) - calc(9rem * 2)
      ) !important; // ? override all navbar type width
    }

    width: 100%; // Full width for other nav options
    left: 0 !important;
  }
}

@media (max-width: 720px) {
  [data-col='1-column'] {
    .header-navbar {
      // Custom width for floating nav
      &.floating-nav {
        width: calc(
                100vw - (100vw - 100%) - calc(2rem * 2)
        ) !important; // ? override all navbar type width
      }

      width: 100%; // Full width for other nav options
      left: 0 !important;
    }
  }
}

.landing-card {
  max-width: 65%;
}

@media (max-width: 720px) {
  .landing-card {
    max-width: 100%;
  }
}

table.dt_transpose tr { display: inline-block; white-space: nowrap; float: left; }
table.dt_transpose th, table.dt_transpose td { display: block; height: 40px; vertical-align: middle;}
table.dt_transpose tbody { float: left;}
table.dt_transpose thead {float: left;}


// pricing card
.pricing-card {

  // plan price value
  .annual-plan {
    .plan-price {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 2rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-14 {
      span {
        font-size: 1.2rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-13 {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 1.4rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-12 {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 1.6rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-11 {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 1.8rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-10 {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 1.8rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
    .plan-price-9 {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 1.8rem!important;
        line-height: 0.8;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
  }
}

.card-header-custom {
  padding: 0 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(34, 41, 47, 0.125);
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  //margin: 5px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}